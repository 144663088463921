import { CourseFilter, getCourses } from './courseMixins';
import { decryptObject } from '../../../../shared/encryption.mjs';

const studentMixins = {
    computed: {
        $_studentMixins_getStudentFromRoute() {
            if (!this.$route) return null;
            return getStudentFromRoute(this.$route, this.$store);
        },
        databaseIsReady() {
            return (this.$store.state.database.loadState === 'READY');
        }
    },
    methods: {
        $_studentMixins_getStudents(limitBy) {
            if (!this.databaseIsReady) return [];
            const { database } = this.$store.state;
            return getStudents(database, limitBy);
        },
        $_studentMixins_getStudentsForCourse(course) {
            if (!this.databaseIsReady) return [];
            return getStudentsForCourse(this.$store.state.database, course);
        },
        $_studentMixins_getStudentsForTeacher(schoolStaffId) {
            if (!this.databaseIsReady) return [];
            return getStudentsForTeacher(this.$store.state.database, schoolStaffId);
        },
        $_studentMixins_getStudentsByEnrollmentIds(studentEnrollmentIds) {
            if (!this.databaseIsReady) return [];
            const { database } = this.$store.state;
            const students = getStudents(database, new StudentFilter({ studentEnrollmentIds }));
            return students || [];
        },
        $_studentMixins_getStudentFromEnrollmentId(studentEnrollmentId) {
            if (!this.databaseIsReady) return [];
            const { database } = this.$store.state;
            const students = getStudents(database, new StudentFilter({ studentEnrollmentId }));
            const [firstItem] = students;
            return firstItem || null;
        },
        $_studentMixins_getStudentInCourse(studentEnrollmentId, courseSectionId) {
            if (!this.databaseIsReady) return [];
            return getStudentInCourse(this.$store.state.database, studentEnrollmentId, courseSectionId);
        },

        $_studentMixins_getReducedStudents(limitBy) {
            if (!this.databaseIsReady) return [];
            const { database } = this.$store.state;
            return getReducedStudents(database, limitBy);
        },
        $_studentMixins_getStudentPdfReports() {
            if (!this.databaseIsReady) return [];
            const { database } = this.$store.state;
            const { studentPdfReports } = database;
            return studentPdfReports || [];
        },
        $_studentMixins_getStudentBiographicals(studentId) {
            if (!this.databaseIsReady) return [];
            const { studentBiographical, schoolTermEncryptionKey } = this.$store.state.database;
            const rows = studentBiographical.filter((s) => s.studentId === studentId);
            return rows.map((s) => {
                if (!schoolTermEncryptionKey || !s.isEncrypted) return s;
                let student = { ...s };
                const { isEncrypted, encryptedData } = student;
                if (isEncrypted) {
                    student = decryptObject(student, encryptedData, schoolTermEncryptionKey);
                    student.isEncrypted = false;
                }
                return student;
            });
        },
    },
};

function StudentFilter(props) {
    this.studentId = props.studentId || false;
    this.studentEnrollmentId = props.studentEnrollmentId || false;
    this.studentEnrollmentIds = props.studentEnrollmentIds || null;
    this.schoolStaffId = props.schoolStaffId || false;
    this.courseSectionId = props.courseSectionId || false;
    this.course = props.course || false;
    return this;
}

function getStudentsForCourse(database, course) {
    return getStudents(database, new StudentFilter({ course }));
}

function getStudentsForTeacher(database, schoolStaffId) {
    return getStudents(database, new StudentFilter({ schoolStaffId }));
}

function getStudents(database, limit) {
    const limitBy = {
        studentEnrollmentId: limit ? limit.studentEnrollmentId : null,
        studentId: limit ? limit.studentId : null,
        studentEnrollmentIds: limit ? limit.studentEnrollmentIds : null,
        schoolStaffId:  limit ? limit.schoolStaffId : null,
        courseSectionId: limit ? limit.courseSectionId : null,
        course: limit ? limit.course : null,
    };

    const { students, courseSectionStudents, markingPeriodStudentAverages } = database;
    const { googleStudents, googleCourseStudents } = database;
    const { markingPeriods, schoolTermEncryptionKey } = database;
    const {
        studentId,
        studentEnrollmentId, schoolStaffId,
        courseSectionId, course,
        studentEnrollmentIds,
    } = limitBy;

    let courseSection = null;
    let courseSections = [];
    let courseSectionIds = [];
    let googleCourseIds = [];

    // requesting students for a primative course section id, no combos
    if (courseSectionId) {
        courseSections = getCourses(database, new CourseFilter({ courseSectionId }));
        if (courseSections.length != 1) { return []; }
        [courseSection] = courseSections;
        courseSectionIds = [courseSection.courseSectionId];
    }

    // requesting students for a fomatted / combo course
    if (course) {
        courseSection = { ...course };
        courseSections = [
            courseSection,
            ...course.comboCourses,
        ];
        courseSectionIds = courseSections.map((x) => x.courseSectionId);

        // unique google course id array
        googleCourseIds = [...new Set(
            courseSections.map((c) => c.googleCourseId),
        )];
    }

    // requesting students for a teacher
    if (schoolStaffId) {
        courseSections = getCourses(database, new CourseFilter({ schoolStaffId }));
        courseSectionIds = courseSections.map((x) => x.courseSectionId);
    }

    return students
        .filter((s) => {
            if (studentEnrollmentId) {
                return s.studentEnrollmentId == studentEnrollmentId;
            }
            if (studentId) {
                return s.studentId == studentId;
            }
            if (studentEnrollmentIds) {
                return studentEnrollmentIds.includes(s.studentEnrollmentId);
            }
            if (courseSectionId || course || schoolStaffId) {
                // only return students in wanted courseSectionIds
                return Boolean(courseSectionStudents
                    .find((css) => s.studentEnrollmentId == css.studentEnrollmentId
                        && courseSectionIds.includes(css.courseSectionId)));
            }
            return true;
        }).map((s) => { // adds courseSectionStudent, google info, and averages
            let student = { ...s };
            student.courseSection = null;
            student.courseSectionId = 0;
            student.courseSectionName = null;
            student.courseSectionStudentId = 0;

            if (courseSections.length > 0) {
                // is the child scheduled for the course?
                const courseSectionStudent = courseSectionStudents
                    .find((css) => s.studentEnrollmentId == css.studentEnrollmentId && courseSectionIds.includes(css.courseSectionId));

                if (courseSectionStudent) {
                    const cs = getCourses(database, new CourseFilter({ courseSectionId: courseSectionStudent.courseSectionId }))[0];
                    if (cs) {
                        student.courseSection = { ...cs };
                        student.courseSectionId = cs.courseSectionId;
                        student.courseSectionName = cs.courseSectionTitle || cs.name;
                        student.courseSectionStudentId = courseSectionStudent.courseSectionStudentId;
                    }
                }

                // is the child scheduled for the google course?
                const { googleEmail } = s;
                student.googleStudent = student.googleStudent || null;
                student.googleUserId = student.googleUserId || 0;
                student.emailAddress = student.emailAddress || null;
                student.googleName = student.googleName || null;

                if (googleEmail) {
                    const googleCourseStudent = googleCourseStudents
                        .find((gcs) => s.googleEmail == gcs.googleEmail
                            && (googleCourseIds.includes(gcs.googleCourseId)));

                    if (googleCourseStudent) {
                        const googleStudent = googleStudents.find((g) => g.googleStudentId == googleCourseStudent.googleStudentId) || null;
                        if (googleStudent) {
                            student.googleStudent = googleStudent;
                            student.emailAddress = `${googleStudent.emailAddress}`;
                            student.googleName = `${googleStudent.firstName} ${googleStudent.lastName}`;
                            student.googleUserId = googleStudent.googleUserId;
                        }
                    }
                }

                const averages = markingPeriodStudentAverages
                    .filter((a) => student.courseSectionStudentId && a.courseSectionStudentId == student.courseSectionStudentId)
                    .map((a) => {
                        const avg = a;
                        avg.markingPeriod = markingPeriods.find((m) => m.schoolTermMarkingPeriodId == a.schoolTermMarkingPeriodId);
                        avg.color = 'Grey';
                        avg.mark = '';
                        if (avg.averageCalculation && avg.averageCalculation.markingPeriod.scaled) {
                            avg.color = avg.averageCalculation.markingPeriod.scaled.color;
                            avg.mark = avg.averageCalculation.markingPeriod.scaled.mark;
                        }
                        return avg;
                    });
                student.markingPeriodAverages = averages || null;
            }

            if (schoolTermEncryptionKey) {
                const { isEncrypted, encryptedData } = student;
                if (isEncrypted) {

                    student = decryptObject(student, encryptedData, schoolTermEncryptionKey);
                    student.isEncrypted = false;
                }
            }
            return student;
        });
}

// Function to get reduced student objects for performance in large lists
function getReducedStudents(database, limitBy = {
    studentEnrollmentId: null,
    studentEnrollmentIds: null,
    schoolStaffId: null,
    courseSectionId: null,
    course: null,
}) {
    const { students, courseSectionStudents } = database;
    const { schoolTermEncryptionKey } = database;
    const { googleStudents, googleCourseStudents } = database;

    const {
        studentEnrollmentId, schoolStaffId,
        courseSectionId, course,
        studentEnrollmentIds,
    } = limitBy;

    let courseSection = null;
    let courseSections = [];
    let courseSectionIds = [];
    let googleCourseIds = [];

    // requesting students for a primative course section id, no combos
    if (courseSectionId) {
        courseSections = getCourses(database, new CourseFilter({ courseSectionId }));
        if (courseSections.length != 1) { return []; }
        [courseSection] = courseSections;
        courseSectionIds = [courseSection.courseSectionId];
    }

    // requesting students for a fomatted / combo course
    if (course) {
        courseSection = { ...course };
        courseSections = [
            courseSection,
            ...course.comboCourses,
        ];
        courseSectionIds = courseSections.map((x) => x.courseSectionId);

        // unique google course id array
        googleCourseIds = [...new Set(
            courseSections.map((c) => c.googleCourseId),
        )];
    }

    // requesting students for a teacher
    if (schoolStaffId) {
        courseSections = getCourses(database, new CourseFilter({ schoolStaffId }));
        courseSectionIds = courseSections.map((x) => x.courseSectionId);
    }

    return students
        .filter((s) => {
            if (studentEnrollmentId) {
                return s.studentEnrollmentId == studentEnrollmentId;
            }
            if (studentEnrollmentIds) {
                return studentEnrollmentIds.includes(s.studentEnrollmentId);
            }
            if (courseSectionId || course || schoolStaffId) {
                // only return students in wanted courseSectionIds
                return Boolean(courseSectionStudents
                    .find((css) => s.studentEnrollmentId == css.studentEnrollmentId
                        && courseSectionIds.includes(css.courseSectionId)));
            }
            return true;
        })
        .map((s) => { // adds courseSectionStudent, google info, and averages
            let student = { ...s };
            student.courseSection = null;
            student.courseSectionId = 0;
            student.courseSectionName = null;
            student.courseSectionStudentId = 0;

            if (courseSections.length > 0) {
                // is the child scheduled for the course?
                const courseSectionStudent = courseSectionStudents
                    .find((css) => s.studentEnrollmentId == css.studentEnrollmentId && courseSectionIds.includes(css.courseSectionId));

                if (courseSectionStudent) {
                    const cs = getCourses(database, new CourseFilter({ courseSectionId: courseSectionStudent.courseSectionId }))[0];
                    if (cs) {
                        student.courseSection = { ...cs };
                        student.courseSectionId = cs.courseSectionId;
                        student.courseSectionName = cs.courseSectionTitle || cs.name;
                        student.courseSectionStudentId = courseSectionStudent.courseSectionStudentId;
                    }
                }

                // is the child scheduled for the google course?
                const { googleEmail } = s;
                student.googleStudent = student.googleStudent || null;
                student.googleUserId = student.googleUserId || 0;
                student.emailAddress = student.emailAddress || null;
                student.googleName = student.googleName || null;

                if (googleEmail) {
                    const googleCourseStudent = googleCourseStudents
                        .find((gcs) => s.googleEmail == gcs.googleEmail
                            && (googleCourseIds.includes(gcs.googleCourseId)));

                    if (googleCourseStudent) {
                        const googleStudent = googleStudents.find((g) => g.googleStudentId == googleCourseStudent.googleStudentId) || null;
                        if (googleStudent) {
                            student.googleStudent = googleStudent;
                            student.emailAddress = `${googleStudent.emailAddress}`;
                            student.googleName = `${googleStudent.firstName} ${googleStudent.lastName}`;
                            student.googleUserId = googleStudent.googleUserId;
                        }
                    }
                }
            }

            if (schoolTermEncryptionKey) {
                const { isEncrypted, encryptedData } = student;
                if (isEncrypted) {
                    student = decryptObject(student, encryptedData, schoolTermEncryptionKey);
                    student.isEncrypted = false;
                }
            }
            return student;
        });
}

function getStudentInCourse(database, studentEnrollmentId, courseSectionId) {
    const students = getStudents(database, new StudentFilter({ studentEnrollmentId, courseSectionId }));
    const [firstItem] = students;
    return firstItem || null;
}

function getStudentGradeLevels(database) {
    const students = getStudents(database, new StudentFilter({}));
    return students.map((s) => s.gradeLevel).filter((gradeLevel, idx, array) => gradeLevel && array.indexOf(gradeLevel) === idx) || [];
}

function getStudentHomeRooms(database) {
    const students = getStudents(database, new StudentFilter({}));
    return students.map((s) => s.homeRoom).filter((homeRoom, idx, array) => homeRoom && array.indexOf(homeRoom) === idx) || [];
}

export function getStudentFromRoute($route, $store) {
    const { studentEnrollmentId } = $route.params;
    if (!studentEnrollmentId) return null;
    const { state } = $store;
    const { database } = state;
    const students = getStudents(database, new StudentFilter({ studentEnrollmentId }));
    const [student] = students;
    return student || null;
}

export {
    studentMixins as default,
    studentMixins,
    getStudents,
    getStudentInCourse,
    getStudentsForCourse,
    getStudentGradeLevels,
    getStudentHomeRooms,
    StudentFilter,
};
